import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Img from "gatsby-image"
import { Container } from "react-bootstrap"
import Doctolib from "../components/doctolib"
import Conseils from "../components/conseils"
import "../styles/global.css"

const GeneratedPage = ({ data, pageContext }) => {
  const {
    title,
    description,
    categories,
    image,
    custom,
  } = data.markdownRemark.frontmatter

  return (
    <Layout>
      <Seo title={title} description={description} />

      <Container className="container">
        <div
          style={{
            position: "relative",
          }}
        >
          <Img
            fluid={image.childImageSharp.fluid}
            style={{
              maxWidth: "1920px",
              height: "400px",
            }}
          />
          <h1
            style={{
              position: "absolute",
              top: "70%",
              left: "25%",
              transform: "translate(-50%,-50%)",
              color: "white",
              fontSize: "2.5em",
              fontWeight: "lighter",
            }}
          >
            L'Ostéopathie,
            <br /> pour les <b>{categories}</b>
          </h1>
        </div>
        <h2
          style={{
            fontWeight: "lighter",
            textAlign: "center",
            marginTop: "50px",
            marginBottom: "50px",
          }}
        >
          Des soins adaptés aux exigences des {categories}
        </h2>
        <div className="page-container-img-text">
          <Img
            fluid={custom.childImageSharp.fluid}
            style={{ width: "200px", height: "200px" }}
          />
          <div style={{ width: "540px", paddingLeft: "16px" }}>
            {description}
          </div>
        </div>
        <Conseils />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "40px",
          }}
        >
          {<Doctolib />}
        </div>
      </Container>
    </Layout>
  )
}

export default GeneratedPage

export const query = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        title
        description
        categories
        image {
          childImageSharp {
            fluid(cropFocus: CENTER, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        custom {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
